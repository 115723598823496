
.barcode-scanner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(0, 0, 0);
    z-index: 1000;
    width: 100%;
}

.barcode-scanner video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.barcode-scanner-overlay {
    position: absolute;
    top: 20%;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: top;
}

.barcode-scanner-close {
    position: fixed;
    top: 10px;
    right: 40px;
    color: #fff;
    font-size: 50px;
    cursor: pointer;
    z-index: 1002;
}