a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

header {
  position: fixed;
  left: 0;
  right: 0;
  height: var(--top-nav-height);
  z-index: 998;
}

header .navbar {
  background-color: var(--theme-light-color);
}

.app-nav {
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  bottom: 0;
  transition: all .2s ease-out;
  color: var(--theme-light-color);
  background-color: var(--theme-bg-color);
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-nav-body {
  padding: 5px;
}


.app-nav .nav-item svg {
  width: 20px;
}


.app-nav-menu-title {
  text-transform: uppercase;
  padding: 12px 20px;
  color: var(--theme-menu-title-color);
  cursor: default;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: .05em;
}

body .app-nav-body .navbar-nav .nav-link {
  align-items: center;
  color: var(--theme-menu-item-color);
  display: flex;
  font-family: Saira,sans-serif;
  font-size: .9375rem;
  padding: .625rem 1.5rem;
}

body.app-nav-body .navbar-nav .nav-link.active.active
 ,body .app-nav-body .navbar-nav .nav-link.active:active
 ,body .app-nav-body .navbar-nav .nav-link.active:hover {
  color: var(--theme-menu-item-hover-color);
}

.app-nav-body .nav-link span {
  margin-left: 10px;
}

.app-nav .nav-link.active {
  background-color: var(--heading-color);
}

.app-nav-path:not(:first-child)::before {
  content: '|';
  padding-right: 0.5rem;
}

.app-nav-bg {
  display: none;
}

.nav-mobile {
  display: none;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: var(--theme-gray-bg-color);
  z-index: 899;
  box-shadow: 5px 0 5px rgba(0,0,0,0.1);
}

.nav-mobile-item {
  text-align: center;
  width: 20%;
  font-size: 0.8rem;
  color: var(--inactive-color);
}

.nav-mobile-item.active {
  color: var(--active-color);
}

.nav-mobile-item svg {
  display: block;
  margin: 0 auto;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

/* Large Devices */
@media (min-width: 992px) {
  header {
    margin-left: var(--sidebar-width);
  }
}

/* Small Devices */
@media (max-width: 991.98px) {
  .nav-mobile {
    display: flex;
  }

  .app-nav {
    left: var(--sidebar-width-neg);
  }
  .app-nav.show {
    transform: translateX(var(--sidebar-width));
  }
  .app-nav-bg.show {
    z-index: 950;
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.2);
    animation: fadeInFromNone 0.5s ease-out;
  }
}