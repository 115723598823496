
.ImageView {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #eee;
    background-repeat: repeat;
}

.ImageView img {
    max-height: 90vh;
    display: block;
    margin: 5vh auto 5px auto;
    border-radius: 15px;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
}