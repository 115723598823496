
.print-view {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;
    padding: 0;
    margin: 0;
}

.print-content {
    position: relative;
    padding: 20px;
    overflow-y: auto;
    height: calc(100% - 60px);
    margin-bottom: 60px;
}

@media print {
    .print-content {
        height: 100vh;
        padding: 0;
        margin-bottom: 0;
        border: 0;
    }
}

.print-view-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1000;
    padding: 10px;
    border-top: 1px solid #ccc;
}