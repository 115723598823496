
.app-top-tools {
    position: fixed;
    z-index: 999;
    background-color: var(--bg-color);
    top: 48px;
    left: var(--sidebar-width);
    right: 0;
    padding: 0 1rem;
}

.app-top-tools-offset {
    padding-top: 40px;
}

/* Small Screen & Mobile */
@media (max-width: 991.98px) {
    .app-top-tools {
        left: 0;
    }
}