
.view-img-upload {
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: center;
    top: 15px;
    right: 15px;
    width: 120px;
    height: 45px;
    font-size: 20px;
    text-align: center;
}

.view-img-upload div {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 50%;
    color: #fff;
    width: 40px;
    margin: 0 0.1rem;
}
