.instance-prompt {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}