@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --bg-color: #fff;
  --sidebar-width: 250px;
  --sidebar-width-neg: -250px;
  --theme-bg-color: rgb(51, 62, 72);
  --theme-light-color: #fff;
  --theme-gray-bg-color: #f3f6f9;
  --theme-border-color: #e9ebec;
  --theme-btn-bg-color: rgb(51, 62, 72);
  --theme-border-radius: 4px;
  --theme-menu-title-color: #fff;
  --theme-menu-item-color: #fff;
  --theme-menu-item-hover-color: #fff;
  --theme-white: #fff;
  --heading-color: #495057;
  --theme-btn-disabled-color: #aaa;
  --top-nav-height: 70px;
  --inactive-color: rgba(0, 0, 0, 0.5);
  --active-color: #000;
  --bg-highlight-color: #ecf65d80;
}

html {
  overflow-x: hidden;
}

body {
  position: relative;
  background-color: var(--bg-color);
}

body .text-primary {
  --bs-primary-rgb: var(--theme-btn-bg-color);
  color: var(--bs-primary-rgb) !important;
}

body .card {
  --bs-card-border-color: var(--theme-border-color);
  --bs-card-cap-bg: var(--theme-gray-bg-color);
}

body .btn-primary {
  --bs-btn-bg: var(--theme-btn-bg-color);
  --bs-btn-border-color: var(--theme-btn-bg-color);
  --bs-btn-hover-bg: var(--theme-btn-bg-color);
  --bs-btn-hover-border-color: var(--theme-btn-bg-color);
}

body .btn-success {
  --bs-btn-bg: var(--theme-btn-bg-color);
  --bs-btn-border-color: var(--theme-btn-bg-color);
}

body .btn,
body .btn-lg {
  --bs-btn-border-radius: var(--theme-border-radius);
}

body .btn:disabled {
  --bs-btn-disabled-bg: var(--theme-btn-disabled-color);
  --bs-btn-disabled-border-color: var(--theme-btn-disabled-color);
}

body a {
  color: var(--theme-bg-color);
  text-decoration: none;
}

body {
  font-family: 'Saira', sans-serif;
}

body .card {
  border-radius: 0;
}

body .form-control {
  border-radius: 0;
}

body .list-group {
  border-radius: 0;
}

.app-body {
  padding-top: var(--top-nav-height);
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
}

.app-body table th {
  background-color: var(--theme-gray-bg-color);
}

/*
.app-actions {
  display: none;
}
*/

.app-actions {
  display: block;
  z-index: 999;
  position: fixed;
  bottom: 100px;
  right: 2rem;
}

.app-actions.app-actions-form {
  bottom: 7rem;
}

.app-actions .btn-lg {
  display: inline-block;
  padding: 1.2rem 1.5rem;
  border-radius: 50%;
  font-size: 1rem;
  margin: 0 0.4rem;
}

.app-actions .btn-lg svg {
  height: 2rem;
  width: 2rem;
}


.app-home-menu .list-group-item svg {
  width: 30px;
  color: #555;
  margin-right: 10px;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
}

.pointer {
  cursor: pointer;
}

.app-buttons-2 {
  position: relative;
}

.app-buttons-2 .list-group-item {
  height: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-buttons-2 .list-group-item a {
  display: block;
}

.app-buttons-2 .list-group-item h2 {
  font-size: 2rem;
  padding: 10px 0;
}

.app-buttons-2 .list-group-item img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  max-height: 50%;
}

.product-img {
  height: 5rem;
}

.view-img {
  display: block;
  max-width: 100%;
  max-height: 400px;
}

.view-img-wrapper {
  position: relative;
}

.app-form-actions {
  z-index: 900;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background-color: var(--theme-gray-bg-color);
  margin: 0 !important;
  height: 80px;
  padding-left: 260px;
}

.overflow-hidden {
  overflow: hidden;
}

.app-body {
  margin-bottom: 100px;
}

.bg-highlight {
  background-color: var(--bg-highlight-color) !important;
}

html .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 0;
}

.active tr, .active td {
  background-color: var(--bg-highlight-color);
}

.text-super {
  color: #995282;
}

.overlay {
  position: fixed;
  top: 50px;
  left: var(--sidebar-width);
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
  overflow: auto;
}




/* Small Screen & Mobile */
@media (max-width: 991.98px) {
  * {
    font-size: 1.2rem;
  }

  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    height: 100vh;
    overflow: auto;
  }

  h1 {
    font-size: 2rem;
  }

  .app-actions {
    bottom: 100px;
  }

  .form-control {
    padding: 10px;
    font-size: 1.3rem;
  }

  .app-body {
    margin-bottom: 150px;
  }

  .app-form-actions {
    padding-left: 1rem;
  }

  body .list-group-item {
    font-size: 1.8rem;
    font-weight: 500;
  }

  body .list-group.list-group-sm .list-group-item {
    font-size: 1.4rem;
    font-weight: 500;
  }

  table.c-datatable td,
  table.c-datatable td a {
    font-size: 1.4rem;
  }

  table.c-datatable td.product-title,
  table.c-datatable td.product-title a {
    font-size: 1rem;
  }

  .view-img {
    display: block;
    max-width: 100%;
    max-height: 30vh;
  }

  .mobile-small-tables table th, .mobile-small-tables table td {
    font-size: 12px;
  }

}

@media (min-width: 992px) {
  .app-body {
    margin-left: var(--sidebar-width);
  }
}